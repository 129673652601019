import React, {useState} from "react";
import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, DatePicker, TimePicker, DateTimePicker } from "@material-ui/pickers";
import es from "date-fns/locale/es";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Text from "../Text/Text";
import styled from "styled-components";
import moment from "moment";
// const StyledDatePicker = styled(DatePicker)`
// 	fieldset{
// 		border-color:var(--fieldSetColorPrimary);
// 	}	
// `;
// const StyledDateTimePicker = styled(DateTimePicker)`
// 	fieldset{
// 		border-color:var(--fieldSetColorPrimary);
// 	}	
// `;
// const StyledTimePicker = styled(TimePicker)`
// 	fieldset{
// 		border-color:var(--fieldSetColorPrimary);
// 	}	
// `;
export default function CalendarPicker(props) {

	const [msg, setMsg] = useState("");
	const onChange = (date) => {
		props.onChange(date.toISOString(), props.name);
		setMsg(null);
		if(date.getHours() < 8 && props.subtype==="datetime"){
			setMsg(`Seleccionaste las ${date.getHours()}am, si deseas seleccionar las ${date.getHours()}pm por favor verifica la información`);
		}
	};
	const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => {
		// 1. Add your processing logic here.
		console.log({value})
	
		// 2. Use the processed value in the returned JSX.
		return (
			<input 
				className="bg-blue-light rounded-3xl px-5 py-2 w-full" 
				StyledTextField={props.StyledTextField} 
				onChange={()=>{}}
				onClick={onClick}
				ref={ref} 
				value={value}
				placeholder={props.label}
			/>
		);
	});
	
	const selectedDate = React.useMemo(()=>props.value  ? new Date(props.value) : null, [props.value]);

	return (
		<>
			<DatePicker 
				showTimeSelect={props.subtype==="datetime"}  
				selected={selectedDate} 
				onChange={onChange} 
				minDate={props.minDate}
				className={props.className}
				maxDate={props.maxDate}
				excludeTimes={props.excludeTimes}
				filterTime={props.filterTime}
				dateFormat={props.subtype==="datetime" ? "MM/dd/yyyy HH:mm" : "MM/dd/yyyy"}
				customInput={<ExampleCustomInput label={props.label} />}
			/>
			{/* <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
				{props.subtype==="datetime" ?
					<StyledDateTimePicker
						size="small"
						inputVariant="outlined"
						format="dd/MM/yyyy HH:mm"
						minutesStep={5}
						ampm={false} 
						value={props.value?props.value:null}
						onChange={onChange}
						openTo={props.openTo&&props.openTo}
					/>


					:
					props.subtype==="time" 
						?
						<StyledTimePicker
							size="small"
							inputVariant="outlined"
							autoOk
							ampm={false} 
							minutesStep={5}
							value={props.value?props.value:null}
							onChange={onChange} 
						/> 
						:
						<StyledDatePicker
							size="small"
							inputVariant="outlined"
							autoOk
							format="dd/MM/yyyy"
							views={["year", "month", "date"]}
							value={props.value?props.value:null}
							onChange={date => props.onChange(date.toISOString(), props.name)} 
							openTo={props.openTo&&props.openTo}
						/>
				}

			</MuiPickersUtilsProvider> */}
			{msg && <div style={{color:"red"}}>{msg}</div>}
		</>
	);
}

CalendarPicker.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	onChange: PropTypes.func,
	name: PropTypes.string,
	openTo: PropTypes.string
};